/* Homepage */  
  .imageHome {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	border-left: 1px solid black;
	border-right: 1px solid black;
  }

  .image-montage {
	opacity: 0; 
	transform: translateY(20px); 
	transition: opacity 2s ease, transform 1.5s ease; 
  }

  
.image-montage.fade-in {
	opacity: 1; 
	transform: translateY(0); 
  }

.centered-text {
	opacity: 0; 
	transform: translateY(50px); 
	transition: opacity 2s ease, transform 1.5s ease; 
}

.centered-text.fade-in {
	opacity: 1; 
	transform: translateY(0); 
}

.testimonial-container {
	opacity: 0; 
	transform: translateY(20px);
	transition: opacity 2s ease, transform 1.5s ease; 
}

.testimonial-container.fade-in{
	opacity: 1;
	transform: translateY(0); 
}

.login-signup-box.fade-in {
	opacity: 1; 
	transform: translateY(0); 
  }

.login-signup-box {
	opacity: 0; 
	transform: translateY(20px); 
	transition: opacity 2s ease, transform 1.5s ease; 
  }

  .fade-in {
	opacity: 0; 
	transform: translateX(0px);
	transition: opacity 2s ease, transform 5s ease;
  }
  
  
  .fade-in-visible {
	opacity: 1; 
	transform: translateX(0);
	
  }
  

  
  .app-info {
	font-size: 16px;
	display: flex;
	flex-wrap: wrap;
	padding: 0% 4%;
	color: rgb(255, 255, 255);
	background-color: linear-gradient(to bottom right,rgb(247, 249, 254),rgb(217, 77, 17));
	width: 100%;
	justify-content: space-between;
  }
  
  .centered-text {
	flex: 0 0 4%; 
	text-align: center;
	margin-top: 20px;
  }
  
  .image-montage {
	display: flex;
	align-items: flex-start;
	margin-left: 20px; 
	flex: 0 0 30%; 
	
  }
  
  .montage-column,
  .montage-center {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 20px; 
  }
  
  .montage-image {
	width: 100px;
	height: 200;
	border: 5px solid #000000; 
	border-radius: 4px;
  }
  
  
  @media only screen and (max-width: 768px) {
	.centered-text,
	.image-montage,
	.montage-column,
	.montage-center {
	  flex: 50 50 100%; 
	  
	}
  }
  
  
  .text-container {
	flex: 0 0 50%;
	margin-left: 20px;
	color: gray;
	white-space: nowrap;
	overflow-x: auto;
  }
  
  .app-info h2 {
	font-size: 24px;
	margin-top: 0;
	font-weight: bold;
	white-space: nowrap; 
  }
  
  .app-info h3 {
	font-size: 20px;
	margin-top: 20px;
	color: rgb(79, 70, 70);
  }
  
  .app-info ul {
	list-style: none;
	margin-top: 20px;
	margin-left: 50px;
  }
  
  .app-info li {
	margin-bottom: 100px;
	padding-right: 60px;
  }
  
  .testimonials {
	background-color: linear-gradient(to bottom right,rgb(247, 249, 254),rgb(217, 77, 17));
	color: black;
	padding: 50px;
	font-size: 24px;
	text-align: center;
	max-width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	overflow-x: auto; 
	white-space: nowrap;
  }
  
  .testimonial-container {
	display: inline-flex; 
	gap: 20px; 
	margin-top: 40px;
	box-sizing: border-box;
	overflow-x: auto; 
  }
  
  .testimonial {
	background-color: linear-gradient(to bottom right,rgb(247, 249, 254),rgb(217, 77, 17));
	color: rgb(0, 0, 0);
	border-radius: 20px; 
	box-shadow: 4px 4px 10px rgba(221, 195, 173, 0.1);
	padding: 40px; 
	text-align: center;
	margin-bottom: 20px; 
  }
  
  .testimonial p:last-child {
	margin-bottom: 0;
  }
  
  .testImg {
	width: 300px;
	height: 300px;
	object-fit: cover; 
	border-radius: 10%;
	border: 5px solid black;
  }
  
  
  .testimonial p {
	font-size: 18px;
	font-style: italic;
	margin-bottom: 20px;
	box-sizing: border-box;
  }
  
  .testimonial h4 {
	font-size: 16px;
	font-weight: bold;
	margin-top: 0;
	box-sizing: border-box;
  }
  
  .testimonial h2 {
	font-size: 36px;
	font-weight: bold;
	margin-top: 0;
	box-sizing: border-box;
  }

  
  .couple-sitting-container {
	position: relative;
	text-align: center;
	width: 100%;
	border: 10px solid black;
	background-color: black;
  }
  
  .couplenoir {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 75%; 
	height: auto; 
  }
  
  .download-now-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  
  .download-now {
	display: flex;
	align-items: center;
	background-color: rgb(227, 226, 222);
	padding: 10px;
	border-radius: 8px;
  }
  
  .blank-box {
	width: 100px; 
	height: 50px; 
	background-color: white; 
	margin-left: 10px;
  }
  
  .social-media {
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(0, 0, 0);
	width: 100%;
	padding-top: 10px;
	padding-bottom: 40px;
	background-color: white;
  }
  
  .social-media a {
	margin: 0 10px;
	color: #000;
	font-size: 24px;
  }
  
  .social-media a:hover {
	color: #3b5998;
  }

  .custom-select select {
	background-color: black;
  }
  
  /* Dashboard */
  
  
  .login {
	text-decoration: none;
  }
  
  .dashboard-container {
	display: flex;
	flex-wrap: wrap;
  }
  
  .dashboardDiv {
	position: absolute;
	margin: 100px;
	font-family: cursive;
	max-width: 100%;
	top: 140%;
	left: 30%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
  }
  
  .passportIconHeader {
	border-radius: 10%;
	max-width: 150px;
	border: 1px solid black;
	margin-right: 100%;
  }
  
  .content-container {
	margin-left: 20rem;
	background-color: #070607;
	color: white;
	width: 60%;
	opacity: 0.9;
	padding: 5%;
	margin-bottom: 200px;
	margin-top: 20px;
  }
  
  .link {
	list-style-type: none;
	color: white;
	font-size: 24px;
	text-decoration: none;
	margin-top: -20px;
	position: relative;
  }
  
  .link::after {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	height: 2px;
	background-color: teal;
	transform: scaleX(0);
	transition: transform 0.5s ease-in-out;
  }
  
  .link:hover::after {
	transform: scaleX(1);
  }
  
  .link h6 {
	margin-bottom: 5px;
  }
  
  .message-board {
	margin-top: 3rem;
  }
  
  .linksContinent {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
  }
  
  .flag {
	position: absolute;
	z-index: -1;
	opacity: 0.4;
  }
  
  .flag-user-list {
	position: absolute;
	z-index: 0;
	opacity: 0.4;
  }

/* Profile page */

  .profileInfo {
	border: 2px solid #ccc;
	padding: 20px;
	border-radius: 10px;
	background-color: #000000;
	margin-top: 10px;
	opacity: 0.9;
	color: white;
	display: flex;
	flex-direction: column;
  }
  
  .profileInfo h1 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 10px;
  }
  
  .profileInfo p {
	font-size: 18px;
	margin-bottom: 5px;
  }
  
  .profileInfo p:first-of-type {
	margin-top: 0;
  }
  
  .profileInfo p:last-of-type {
	margin-bottom: 0;
  }

/* Initial state: small size and scaled down */
.observable-div {
	transform: scale(0); /* Fully collapsed */
	opacity: 0; /* Hidden initially */
	transition: transform 0.8s ease-out, opacity 0.8s ease-out; /* Smooth scaling and fading */
	transform-origin: center; /* Ensure it scales from the center */
  }
  
  /* When the div becomes visible */
  .observable-div.fade-in {
	transform: scale(1); /* Full size */
	opacity: 1; /* Fully visible */
  }
  
  .userImg {
	padding: 2%;
	width: 250px;
	height: 250px;
	border-radius: 25%;
  }
  
  .videoContainer {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }
  
  .videoContainer h2 {
	margin-bottom: 10px;
  }
  
  video {
	width: 100%;
	max-width: 400px;
	height: auto;
  }
  
  /* Media Queries */
  
  @media only screen and (max-width: 768px) {
	.profileInfo {
	  flex-direction: column;
	  align-items: center;
	}
  
	.videoContainer {
	  margin-top: 20px;
	  align-items: center;
	  margin-left: 0;
	}
  
	.userImg {
	  margin-top: 20px;
	}
  }
  